import React, { useState, useEffect } from "react";
import BSWAPImg from "../../Images/ProductLogos/Bswap.svg";
import BSWAPHeading from "../../Images/BSWAP.png";
import TickImg from "../../Images/Tick.svg";
import RightArrow from "../../Images/RightArrow.svg";
import AIImg from "../../Images/AIImg.svg";
import HandImg from "../../Images/HandImg.svg";
import { useNavigate } from "react-router-dom";

import Source from "../../Images/BSWAPAllSets/Source.jpg";

import Background1 from "../../Images/BSWAPAllSets/BackGrounds/01_bg.jpg";
import Background2 from "../../Images/BSWAPAllSets/BackGrounds/02_bg.jpg";
import Background3 from "../../Images/BSWAPAllSets/BackGrounds/03_bg.jpg";
import Background4 from "../../Images/BSWAPAllSets/BackGrounds/04_bg.jpg";

import Output1 from "../../Images/BSWAPAllSets/BSWAPGens/01.jpg";
import Output2 from "../../Images/BSWAPAllSets/BSWAPGens/02.jpg";
import Output3 from "../../Images/BSWAPAllSets/BSWAPGens/03.jpg";
import Output4 from "../../Images/BSWAPAllSets/BSWAPGens/04.jpg";

export default function BSWAPHome() {
  const navigate = useNavigate();
  const row1 = [Source];
  const row2 = [Background1, Background2, Background3, Background4];

  const Output = [Output1, Output2, Output3, Output4, Source];

  const [selectedRow1Index, setSelectedRow1Index] = useState(-1);
  const [selectedRow2Index, setSelectedRow2Index] = useState(0);
  const [selectedRow3Index, setSelectedRow3Index] = useState(0);

  const [displayImageIndex, setDisplayImageIndex] = useState();

  useEffect(() => {
    const calculateImageIndex = () => {
      if (selectedRow1Index === 0 && selectedRow3Index === 0) {
        setSelectedRow3Index(5);
        setSelectedRow2Index(-1);
        return 4;
      }
      if (selectedRow2Index >= 0 && selectedRow2Index < row2.length) {
        setSelectedRow3Index(0);
        setSelectedRow1Index(-1);
        return selectedRow2Index;
      }
      return 4;
    };

    setDisplayImageIndex(calculateImageIndex());
  }, [selectedRow1Index, selectedRow2Index, row2.length]);

  return (
    <div className="flex justify-center items-start gap-[8vw]  bg-[#F9F4FF]  pt-[15vh] px-[4vw] pb-[10vh]">
      <div className="flex justify-center items-start">
        {/* <p className="text-[1.2vw]">
          Selected Image Index: {displayImageIndex}
        </p> */}
        <img
          src={Output[displayImageIndex]}
          alt="Selected Display"
          className="w-[38vw]  object-contain"
        />
      </div>
      <div className="flex flex-col gap-[2vh] justify-start items-start">
        <div className="flex flex-col gap-[0.8vh] justify-start items-start">
          <div className="flex justify-start items-center gap-[2vw]">
            <img
              src={BSWAPImg}
              alt="BSWAPImg"
              className="w-[5vw] h-[12vh] object-contain"
            />

            <img
              src={BSWAPHeading}
              alt="BSWAPHeading"
              className="w-[20vw] min-h-[12vh] object-contain"
            />
          </div>
          <div className="flex flex-col justify-start items-start text-[#434444] text-[1.2vw] font-[600]">
            <span className=" leading-[3vh]">
              Want to retain your model? No problem. Shoot them in studio and
            </span>
            <span className=" leading-[3vh]">
              teleport them to any part of the world.
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start text-[1vw] gap-[1vh]">
          <div className="flex justify-start items-start text-[#303030] text-[1vw] font-semibold">
            Made for
          </div>
          <div className="flex justify-between items-center gap-[4.5vw]">
            <div className="flex flex-col items-start justify-start gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Fashion Brands</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Photography Studios</span>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start  gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Digital Agencies</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Online Marketplaces</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-[1vw]">
          <div className="flex flex-col justify-center items-center gap-[0.5vh]">
            <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
              Source
            </div>
            <div className="flex justify-center items-center gap-[1vw]">
              {row1.map((img, index) => (
                <div
                  key={index}
                  className={`relative cursor-pointer  ${
                    selectedRow1Index === index
                       ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                      : "border-[0.2vw] border-[#FCFCFD]"
                  }
                  `}
                  onClick={() => setSelectedRow1Index(index)}
                >
                  <img
                    src={img}
                    alt={`Row 1 Image ${index + 1}`}
                    className="w-[6vw] object-contain"
                  />
                  <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                    Real
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Row 3 */}
          <div className="flex flex-col justify-start items-start gap-[0.5vh]">
            <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
              Background
            </div>
            <div className="flex justify-start items-center gap-[1vw]">
              {row2.map((img, index) => (
                <div
                  key={index}
                  className={`relative cursor-pointer border-[0.1vw] ${
                    selectedRow2Index === index
                       ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                      : "border-[0.2vw] border-[#FCFCFD]"
                  }`}
                  onClick={() => setSelectedRow2Index(index)}
                >
                  <img
                    src={img}
                    alt={`Row 3 Image ${index + 1}`}
                    className="w-[6vw] object-contain"
                  />
                  <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                    AI
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center gap-[1vw] pt-[2vh] pb-[2vh]">
          <button
            className="flex justify-center items-center px-[2vw] py-[1.1vh]  border-[0.1vw] rounded-[0.2vw] text-white bg-[#0CB6D8] text-[1vw] hover:bg-[#10A9C7]"
            onClick={() => {
              window.open("https://tool.neuropixel.ai/sign-up");
            }}
          >
            TRY IT NOW
          </button>
          <button className="flex justify-center items-center px-[1.4vw] py-[1.1vh] border-[0.01vw] rounded-[0.2vw] bg-white text-[#0CB6D8] text-[1vw] border-[#0CB6D8] hover:bg-[#F2F4F7]">
            <div
              className="flex justify-center items-center gap-[0.5vw]"
              onClick={() => {
                navigate("/BSWAP");
              }}
            >
              <span>VIEW SAMPLES</span>
            </div>
          </button>
        </div>

        <div className="flex flex-col gap-[1.5vh]">
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5FFDC] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw]">
            <img
              src={AIImg}
              alt="AIImg"
              className="w-[3vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw]">
              Much more sophisticated than a simple background change, we adapt
              your selected background to match the lighting of your input
              image.
            </div>
          </div>
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5EDFF] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw] ">
            <img
              src={HandImg}
              alt="HandImg"
              className="w-[2.5vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw]">
              On-location images will drive a higher conversion, get your a
              better ROI on your digital ads, and save costs on expensive
              on-location shoots.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
