import React, { useState, useEffect } from "react";
import LiftImg from "../../Images/ProductLogos/Lift.svg";
import LiftHeading from "../../Images/LIFT.png";
import TickImg from "../../Images/Tick.svg";
import RightArrow from "../../Images/RightArrow.svg";
import AIImg from "../../Images/AIImg.svg";
import HandImg from "../../Images/HandImg.svg";
import { useNavigate } from "react-router-dom";

import Source from "../../Images/LiftAllAssests/Source.jpg";

import Model1 from "../../Images/LiftAllAssests/Model/Asian.jpg";
import Model2 from "../../Images/LiftAllAssests/Model/Black.jpg";
import Model3 from "../../Images/LiftAllAssests/Model/Blonde.jpg";

import bg1 from "../../Images/LiftAllAssests/Backgrounds/01.jpg";
import bg2 from "../../Images/LiftAllAssests/Backgrounds/02.jpg";
import bg3 from "../../Images/LiftAllAssests/Backgrounds/03.jpg";
import bg4 from "../../Images/LiftAllAssests/Backgrounds/04.jpg";

import Outpu1 from "../../Images/LiftAllAssests/LiftGens/01/01a..jpg";
import Outpu2 from "../../Images/LiftAllAssests/LiftGens/02/02a..jpg";
import Outpu3 from "../../Images/LiftAllAssests/LiftGens/03/03a..jpg";
import Outpu4 from "../../Images/LiftAllAssests/LiftGens/04/04a.jpg";
import Outpu5 from "../../Images/LiftAllAssests/LiftGens/01/01b..jpg";
import Outpu6 from "../../Images/LiftAllAssests/LiftGens/02/02b..jpg";
import Outpu7 from "../../Images/LiftAllAssests/LiftGens/03/03b..jpg";
import Outpu8 from "../../Images/LiftAllAssests/LiftGens/04/04b.jpg";
import Outpu9 from "../../Images/LiftAllAssests/LiftGens/01/01c..jpg";
import Outpu10 from "../../Images/LiftAllAssests/LiftGens/02/02c..jpg";
import Outpu11 from "../../Images/LiftAllAssests/LiftGens/03/03c..jpg";
import Outpu12 from "../../Images/LiftAllAssests/LiftGens/04/04c.jpg";

export default function LiftHome() {
  const navigate = useNavigate();

  const Output = [
    Outpu1,
    Outpu2,
    Outpu3,
    Outpu4,
    Outpu5,
    Outpu6,
    Outpu7,
    Outpu8,
    Outpu9,
    Outpu10,
    Outpu11,
    Outpu12,
    Source,
  ];

  const ModelImages = { Model2, Model3, Model1 };
  const Backgrounds = { bg1, bg2, bg3, bg4 };

  const [selectedRow1Index, setSelectedRow1Index] = useState(-1);
  const [selectedRow2Index, setSelectedRow2Index] = useState(0);
  const [selectedRow3Index, setSelectedRow3Index] = useState(0);

  const row1 = [Source];
  const row2 = [ModelImages.Model2, ModelImages.Model3, ModelImages.Model1];
  const row3 = [
    Backgrounds.bg1,
    Backgrounds.bg2,
    Backgrounds.bg3,
    Backgrounds.bg4,
  ];

  const [displayImageIndex, setDisplayImageIndex] = useState();
  useEffect(() => {
    const calculateImageIndex = () => {
      if (selectedRow1Index === 0) {
        if (selectedRow2Index !== -1 && selectedRow3Index === -1) {
          setSelectedRow2Index(selectedRow2Index);
          setSelectedRow3Index(0);
          setSelectedRow1Index(1);
          return (
            row2.length * row3.length * 0 +
            row3.length * selectedRow2Index +
            selectedRow3Index
          );
        }
        if (selectedRow3Index !== -1 && selectedRow2Index === -1) {
          setSelectedRow2Index(0);
          setSelectedRow3Index(selectedRow3Index);
          setSelectedRow1Index(1);
          return (
            row2.length * row3.length * 0 +
            row3.length * selectedRow2Index +
            selectedRow3Index
          );
        }
        setSelectedRow2Index(-1);
        setSelectedRow3Index(-1);
        return 12;
      }
      return (
        row2.length * row3.length * 0 +
        row3.length * selectedRow2Index +
        selectedRow3Index
      );
    };

    setDisplayImageIndex(calculateImageIndex());
  }, [
    selectedRow1Index,
    selectedRow2Index,
    selectedRow3Index,
    row2.length,
    row3.length,
  ]);

  return (
    <div className="flex justify-center items-center gap-[8vw] bg-[#FFF6E9] py-[10vh]">
      <div className="flex flex-col gap-[1.5vh] justify-center items-start">
        <div className="flex flex-col gap-[0.8vh] justify-start items-start">
          <div className="flex justify-start items-center gap-[2vw]">
            <img
              src={LiftImg}
              alt="LiftImg"
              className="w-[5vw] h-[12vh] object-contain"
            />

            <img
              src={LiftHeading}
              alt="Lift Heading"
              className="w-[12vw] h-[12vh] object-contain"
            />
          </div>
          <div className="flex flex-col justify-start items-start text-[#434444] text-[1.2vw] font-[600]">
            <span className="leading-[2vh]">
              Convert studio-shot catalog images into immersive editorial images
            </span>
            <span>with photorealistic synthetic humans and backgrounds.</span>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start text-[1vw] gap-[1vh]">
          <div className="flex justify-start items-start text-[#303030] text-[1vw] font-semibold">
            Made for
          </div>
          <div className="flex justify-between items-center gap-[4.5vw]">
            <div className="flex flex-col items-start justify-start gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Fashion Brands</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Photography Studios</span>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start  gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Digital Agencies</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Online Marketplaces</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[1vh]">
          <div className="flex flex-row gap-[2vh] overflow-hidden">
            {/* Row 1 */}
            <div className="flex flex-col justify-center items-center gap-[0.5vh]">
              <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
                Source
              </div>
              <div className="flex justify-center items-center gap-[1vw]">
                {row1.map((img, index) => (
                  <div
                    key={index}
                    className={`relative cursor-pointer border-[0.1vw] ${
                      selectedRow1Index === index
                        ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                        : "border-[0.2vw] border-[#FCFCFD]"
                    }`}
                    onClick={() => setSelectedRow1Index(index)}
                  >
                    <img
                      src={img}
                      alt={`Row 1 Image ${index + 1}`}
                      className="w-[6vw] object-contain"
                    />
                    <div
                      className="absolute flex items-center justify-center px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white mx-[0.1vw] mb-[0.2vh]"
                      // style={{
                      //   background:
                      //     "linear-gradient(90deg, #0CB6D8 0%, #1ACC76 100%, #0CB6D8 100%)",
                      // }}
                    >
                      Real
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Row 2 */}
            <div className="flex flex-col justify-center items-center gap-[0.5vh]">
              <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
                Model
              </div>
              <div className="flex justify-center items-center gap-[1vw]">
                {row2.map((img, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer border-[0.1vw] relative ${
                      selectedRow2Index === index
                        ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                        : "border-[0.2vw] border-[#FCFCFD]"
                    }`}
                    onClick={() => setSelectedRow2Index(index)}
                  >
                    <img
                      src={img}
                      alt={`Row 2 Image ${index + 1}`}
                      className="w-[6vw] object-contain"
                    />
                    <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                      AI
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Row 3 */}
          <div className="flex flex-col justify-start items-start gap-[0.5vh]">
            <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
              Background
            </div>
            <div className="flex justify-start items-center gap-[1vw]">
              {row3.map((img, index) => (
                <div
                  key={index}
                  className={`cursor-pointer border-[0.1vw] relative ${
                    selectedRow3Index === index
                      ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                      : "border-[0.2vw] border-[#FCFCFD]"
                  }`}
                  onClick={() => setSelectedRow3Index(index)}
                >
                  <img
                    src={img}
                    alt={`Row 3 Image ${index + 1}`}
                    className="w-[6vw] object-contain"
                  />
                  <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                    AI
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center gap-[1vw] pt-[0.5vh] pb-[2vh]">
          <button
            className="flex justify-center items-center px-[2vw] py-[1.1vh]  border-[0.1vw] rounded-[0.2vw] text-white bg-[#0CB6D8] text-[1vw] hover:bg-[#10A9C7]"
            onClick={() => {
              window.open("https://tool.neuropixel.ai/sign-up");
            }}
          >
            TRY IT NOW
          </button>
          <button className="flex justify-center items-center px-[1.4vw] py-[1.1vh] border-[0.01vw] rounded-[0.2vw] bg-white text-[#0CB6D8] text-[1vw] border-[#0CB6D8] hover:bg-[#F2F4F7]">
            <div
              className="flex justify-center items-center gap-[0.5vw]"
              onClick={() => {
                navigate("/Lift");
              }}
            >
              <span>VIEW SAMPLES</span>
              {/* <img
                src={RightArrow}
                alt="Lift Image"
                className="w-[1vw] h-[2vh] object-contain"
              /> */}
            </div>
          </button>
        </div>

        <div className="flex flex-col gap-[1.5vh]">
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5FFDC] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw]">
            <img
              src={AIImg}
              alt="AIImg"
              className="w-[3vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw]">
              Our proprietary Photonics Generator adapts the selected model and
              background to mirror the lighting conditions of your input
              photograph.
            </div>
          </div>
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5EDFF] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw] ">
            <img
              src={HandImg}
              alt="HandImg"
              className="w-[2.5vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw] ">
              Drives conversion on platform, eliminates expensive on-location
              photoshoots, and gets you a higher ROI on your digital ads.
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-start items-start">
        {/* <p className="text-[1.2vw]">
          Selected Image Index: {displayImageIndex}
        </p> */}
        <img
          src={Output[displayImageIndex]}
          alt="Selected Display"
          className="w-[38vw] object-contain"
        />
      </div>
    </div>
  );
}
