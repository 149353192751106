import React, { useState, useEffect } from "react";
import serviceImg from "../../Images/Services.png";
import MSWAPHeading from "../../Images/MSWAP.png";
import TickImg from "../../Images/Tick.svg";
import RightArrow from "../../Images/RightArrow.svg";
import AIImg from "../../Images/AIImg.svg";
import HandImg from "../../Images/HandImg.svg";
import { useNavigate } from "react-router-dom";

import Source from "../../Images/MSWAPAllSets/Source.jpg";

import Model1 from "../../Images/ServiceAllAssests/Source/Source 01.jpg";
import Model2 from "../../Images/ServiceAllAssests/Source/Source 02.jpg";
import Model3 from "../../Images/ServiceAllAssests/Source/Source 03.jpg";
import Model4 from "../../Images/ServiceAllAssests/Source/Source 04.jpg";

import Output1 from "../../Images/ServiceAllAssests/Gen/Output 01.jpg";
import Output2 from "../../Images/ServiceAllAssests/Gen/Output 02.jpg";
import Output3 from "../../Images/ServiceAllAssests/Gen/Output 03.jpg";
import Output4 from "../../Images/ServiceAllAssests/Gen/Output 04.jpg";

export default function Service() {
  const navigate = useNavigate();

  const row2 = [Model1, Model2, Model3, Model4];

  const Output = [Output1, Output2, Output3, Output4, Source];

  const [selectedRow2Index, setSelectedRow2Index] = useState(0);

  const [displayImageIndex, setDisplayImageIndex] = useState();

  useEffect(() => {
    const calculateImageIndex = () => {
      return selectedRow2Index;
    };

    setDisplayImageIndex(calculateImageIndex());
  }, [selectedRow2Index, row2.length]);

  return (
    <div
      className="flex justify-center items-start gap-[8vw]  bg-[#FAFAFA]  pt-[15vh] px-[4vw] pb-[10vh]"
      style={{
        background: "linear-gradient(90deg, #1ACC7605  0%,  #0CB6D83D 100%)",
      }}
    >
      <div className="flex flex-col gap-[2vh] justify-start items-start w-[40vw]">
        <div className="flex flex-col gap-[0.2vh] justify-start items-start">
          <div className="flex justify-start items-center gap-[1vw]">
            <img
              src={serviceImg}
              alt="serviceImg"
              className="w-[5vw] h-[8vh] object-contain"
            />

            <h1 className="text-[5vw] font-extrabold text-[#303030] flex items-center justify-center leading-[12vh]">
              SERVICES
            </h1>
          </div>
          <div className="flex flex-col justify-start items-start text-[#434444] text-[1.2vw] font-[600]">
            <span className="leading-[3vh]">
              We process the batch end-to-end based on your
            </span>
            <span className="leading-[3vh]">moodboard and feedback.</span>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start text-[1vw] gap-[1vh] pt-[1vh]">
          <div className="flex justify-start items-start text-[#303030] text-[1vw] font-semibold">
            Our Service delivery mode is ideal for you if...
          </div>
          <div className="flex flex-col justify-start items-start gap-[0.5vh]">
            <div className="flex flex-col items-start justify-start gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>You have large batches and are short on time</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>You prefer outcomes rather than tools</span>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start  gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>You don’t have the internal bandwidth</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>
                  You have complex requirements - multiple people, interactions,
                  etc
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start items-start text-[#434444] text-[1.2vw] font-[600]">
          <span className="leading-[3vh]">
            Some examples with more complex requirements than what
          </span>
          <span className="leading-[3vh]">
            our self-service products can offer are below:
          </span>
        </div>

        <div className="flex gap-[1vw]">
          <div className="flex flex-col justify-start items-start gap-[0.5vh]">
            <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
              Source
            </div>
            <div className="flex justify-start items-center gap-[1vw]">
              {row2.map((img, index) => (
                <div
                  key={index}
                  className={`relative cursor-pointer border-[0.1vw] ${
                    selectedRow2Index === index
                      ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                      : "border-[0.2vw] border-[#FCFCFD]"
                  }`}
                  onClick={() => setSelectedRow2Index(index)}
                >
                  <img
                    src={img}
                    alt={`Row 3 Image ${index + 1}`}
                    className="w-[6vw] object-contain"
                  />
                  <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                    Real
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center gap-[1vw] pt-[2vh] pb-[2vh]">
          <button
            className="flex justify-center items-center px-[2vw] py-[1.1vh]  border-[0.1vw] rounded-[0.2vw] text-white bg-[#0CB6D8] text-[1vw] hover:bg-[#10A9C7]"
            onClick={() => {
              const contactSection = document.getElementById("contact");
              if (contactSection) {
                contactSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            Get in Touch
          </button>
        </div>
      </div>
      <div className="flex justify-center items-start">
        {/* <p className="text-[1.2vw]">
          Selected Image Index: {displayImageIndex}
        </p> */}
        <img
          src={Output[displayImageIndex]}
          alt="Selected Display"
          className="w-[38vw]  object-contain"
        />
      </div>
    </div>
  );
}
