import React, { useState, useEffect, useRef } from "react";
import RippleImg from "../../Images/ProductLogos/RIPPlLE.svg";
import RippleHeading from "../../Images/Ripple.png";
import TickImg from "../../Images/Tick.svg";
import RightArrow from "../../Images/RightArrow.svg";
import AIImg from "../../Images/AIImg.svg";
import HandImg from "../../Images/HandImg.svg";
import { useNavigate } from "react-router-dom";

import Model1 from "../../Images/RippleAllAssets/01/Source.jpg";
import Model2 from "../../Images/RippleAllAssets/02/Source.png";
import Model3 from "../../Images/RippleAllAssets/03/Source.png";
import Model4 from "../../Images/RippleAllAssets/O4/Source.png";

import Output1 from "../../Images/RippleAllAssets/01/Output.mp4";
import Output2 from "../../Images/RippleAllAssets/02/Output.mp4";
import Output3 from "../../Images/RippleAllAssets/03/Output.mp4";
import Output4 from "../../Images/RippleAllAssets/O4/Output.mp4";

export default function Ripple() {
  const navigate = useNavigate();

  const row2 = [Model1, Model2, Model3, Model4];

  const Output = [Output1, Output2, Output3, Output4];

  const [selectedRow2Index, setSelectedRow2Index] = useState(0);

  const [displayImageIndex, setDisplayImageIndex] = useState();
  const [isPlaying, setIsPlaying] = useState(true);

  const videoRef = useRef(null);

  useEffect(() => {
    setDisplayImageIndex(selectedRow2Index);
  }, [selectedRow2Index]);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div className="flex justify-center items-start gap-[8vw]  bg-[#FAF9E5]  pt-[15vh] px-[4vw] pb-[10vh]">
      <div className="flex flex-col gap-[2vh] justify-start items-start">
        <div className="flex flex-col gap-[0.8vh] justify-start items-start">
          <div className="flex justify-start items-center gap-[2vw]">
            <img
              src={RippleImg}
              alt="RippleImg"
              className="w-[5vw] h-[12vh] object-contain"
            />

            <img
              src={RippleHeading}
              alt="RippleHeading"
              className="w-[20vw] min-h-[12vh] object-contain"
            />
          </div>
          <div className="flex flex-col justify-start items-start text-[#434444] text-[1.2vw] font-[600]">
            <span className="leading-[3vh]">
              Convert any image to a short reel.
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-start items-start text-[1vw] gap-[1vh]">
          <div className="flex justify-start items-start text-[#303030] text-[1vw] font-semibold">
            Made for
          </div>
          <div className="flex justify-between items-center gap-[4.5vw]">
            <div className="flex flex-col items-start justify-start gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Fashion Brands</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Photography Studios</span>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start  gap-[0.5vh] text-[#434444]">
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Digital Agencies</span>
              </div>
              <div className="flex justify-center items-center gap-[0.5vw]">
                <img src={TickImg} alt="TickImg" className="w-[1vw] h-[2vh]" />
                <span>Online Marketplaces</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-[1vw]">
          {/* Row 3 */}
          <div className="flex flex-col justify-start items-start gap-[0.5vh]">
            <div className="flex items-center justify-start text-[1vw] text-[#303030] w-full font-semibold">
              Source
            </div>
            <div className="flex justify-start items-center gap-[1vw]">
              {row2.map((img, index) => (
                <div
                  key={index}
                  className={`relative cursor-pointer border-[0.1vw] ${
                    selectedRow2Index === index
                      ? "border-transparent bg-gradient-to-r from-[#0CB6D8] to-[#1ACC76] rounded border-[0.2vw]"
                      : "border-[0.2vw] border-[#FCFCFD]"
                  }`}
                  onClick={() => setSelectedRow2Index(index)}
                >
                  <img
                    src={img}
                    alt={`Row 3 Image ${index + 1}`}
                    className="w-[6vw] object-contain"
                  />
                  <div className="absolute flex items-center justify-center  px-[0.5vw] py-[0.2vh] bottom-0 right-0 bg-[#0CB6D8] rounded-[50vw] text-[0.7vw] text-white  mx-[0.2vw] mb-[0.2vh]">
                    AI
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-start items-center gap-[1vw] pt-[2vh] pb-[2vh]">
          <button
            className="flex justify-center items-center px-[2vw] py-[1.1vh]  border-[0.1vw] rounded-[0.2vw] text-white bg-[#0CB6D8] text-[1vw] hover:bg-[#10A9C7]"
            onClick={() => {
              window.open("https://tool.neuropixel.ai/sign-up");
            }}
          >
            TRY IT NOW
          </button>
        </div>

        <div className="flex flex-col gap-[1.5vh]">
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5FFDC] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw]">
            <img
              src={AIImg}
              alt="AIImg"
              className="w-[3vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw]">
              This is still a prototype solution so expect the videos to be a
              bit rough around the edges, but this is improving rapidly.
            </div>
          </div>
          <div className="flex justify-center items-center gap-[1vw] bg-[#B5EDFF] px-[1vw] py-[0.7vh] rounded-[0.2vw] w-[40vw] ">
            <img
              src={HandImg}
              alt="HandImg"
              className="w-[2.5vw] h-[6vh] object-contain"
            />
            <div className="flex justify-start items-center text-[#434444] text-[0.9vw]">
              Get dramatically higher performing social media content at a triny
              fraction of the cost/time of shooting on-location content.
            </div>
          </div>
        </div>
      </div>
      <div className="relative flex justify-center items-center">
        <video
          ref={videoRef}
          src={Output[displayImageIndex]}
          autoPlay
          loop
          muted
          className="w-[38vw] object-contain"
        />

        <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
          <button
            onClick={togglePlayPause}
            className="p-[0.9vw] bg-black bg-opacity-50 rounded-full text-white"
            aria-label={isPlaying ? "Pause" : "Play"}
          >
            {isPlaying ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M9 19H6V5h3v14zm9-14h-3v14h3V5z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-8 h-8"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
